const GetDomain = (language) => {
  switch (language) {
    case "es_ES":
        return `https://www.888sport.es/deeplink/?sr=1919700&selections=`;
    case "da_DK":
        return `https://www.888sport.dk/deeplink/?sr=1919700&selections=`;
    case "it_IT":
        return `https://www.888sport.it/?sr=1919700/calcio`;
    case "en_US":
        return `https://affiliates.routy.app/route/69289?affId=10234&ts=5002572&selections=`; // UK ?
    case "en_CA":
        return `https://www.888sport.ca/deeplink/?sr=1919700&selections=`;
    case "de_DE":
        return `https://www.888sport.de/deeplink/?sr=1919700&selections=`;
    case "sv_SE":
        return `https://www.888sport.se/deeplink/?sr=1919700&selections=`;
    case "ro_RO":
        return `https://www.888sport.ro/deeplink/?sr=1919700&selections=`;
    default:
        return `https://affiliates.routy.app/route/68803?affId=10234&ts=5002572&selections=`; // UK
  }
};
export default GetDomain;
